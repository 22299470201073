<template>
    <table :class="theme.table.table">
        <thead>
        <tr :class="s.tableHeader">
            <th :class="[theme.table.headerText, theme.table.alignLeft]">
                User
            </th>
            <th
                :class="[theme.table.headerText, theme.table.alignCenter]"
                v-for="policy in availablePolicies"
                :key="policy"
            >
                <span :class="s.infoRow">
                    {{ $store.getters.localization.AccountAccesses[policy] || policy.capitalize() }}
                    <Info
                        :text="infoTexts[policy]"
                        :size="12"
                    />
                </span>
            </th>
            <th :class="[theme.table.headerText, theme.table.alignCenter]">
                Actions
            </th>
        </tr>
        </thead>
        <tbody>
        <ManagerRow
            ref="Manager"
            current-ref="Manager"
            @open="openModal($event)"
            v-for="manager in managers"
            :key="manager.id"
            :manager="manager"
            :is-institutional-allowed="isInstitutionalAllowed"
        />
        <tr v-if="hasNoItems">
            <td :colspan="availablePolicies.length + 2">
                <NoData
                    is-user
                    has-position-relative
                    text="Right now, you have no managers or active requests to manage your account."
                    :class="s.noData"
                />
            </td>
        </tr>
        </tbody>
        <tbody v-if="managersRequests.length > 0">
        <tr :class="s.pending">
            <td :class="s.pendingContainer" :colspan="availablePolicies.length + 2">
                <Icon icon="clock" />
                <span>Pending</span>
            </td>
        </tr>
        </tbody>
        <tbody>
        <ManagerRow
            ref="ManagerPending"
            current-ref="ManagerPending"
            @open="openModal($event)"
            v-for="manager in managersRequests"
            :key="manager.requestGuid"
            :manager="manager"
            is-request
            :is-institutional-allowed="isInstitutionalAllowed"
        />
        <InviteManager
            :managers-g-u-i-ds="managersGUIDs"
            :manager-requests-g-u-i-ds="managerRequestsGUIDs"
            ref="Invite"
            :is-institutional-allowed="isInstitutionalAllowed"
            @open="openModal($event)"
        />
        </tbody>
        <modal
            :clickToClose="false"
            name="confirm-finance"
            id="confirmModal"
        >
            <div :class="[s.row, s.header, s.alignCenter, s.borderBottom]">
                <Icon icon="alert" :class="s.mr8" />
                <span :class="s.headerText">Confirm Finance sharing</span>
            </div>
            <div :class="[s.row, s.mainContent]">
                <span :class="s.mainContentText">
                    Please note, this right allows the manager to deposit and withdraw funds from this trading account.
                </span>
            </div>
            <div :class="[s.row, s.buttonsBlock]">
                <div :class="[s.col, s.w100]">
                    <Button
                        @click="cancel"
                        button-type="error"
                    >
                        <template>
                            CANCEL
                        </template>
                    </Button>
                </div>
                <div :class="[s.col, s.w100]">
                    <Button
                        @click="confirm"
                        button-type="primary"
                    >
                        <template>
                            CONFIRM
                        </template>
                    </Button>
                </div>
            </div>
        </modal>
    </table>
</template>

<script>
import { mapGetters } from 'vuex';

import Icon from 'UI/Icon.vue';
import theme from 'Theme';
import NoData from 'UI/NoData.vue';
import Button from 'Control/Button.vue';
import Info from 'UI/Info.vue';

import ManagerRow from './ManagerRow.vue';
import InviteManager from './InviteManager.vue';

export default {
    name: 'Accounts.PermissionsSettings.ManagersTable',
    components: {
        Info,
        Button,
        ManagerRow,
        InviteManager,
        Icon,
        NoData,
    },
    data() {
        return {
            managersRequests: [],
            managersGUIDs: [],
            managerRequestsGUIDs: [],
            theme,
            modalRef: '',
            infoTexts: {
                trading: 'Allows trading on centralized exchanges, managing orders, and accessing trading data.',
                allocation: 'Permits moving crypto and fiat between custody storage, trading platforms, and OTC desks.',
                finance: 'Grants the ability to deposit and withdraw funds from the trading account. It’s not recommended to give this right to a manager, as they could withdraw the account owner’s funds.',
                portfolio: 'Allows viewing of portfolio analytics and key trading metrics of the account.',
                statements: 'Enables access to financial reports and exports in XLSX format.',
                defi: 'Provides access to decentralized finance services like DEXs and lending protocols. The manager can perform token swaps and provide liquidity for AMMs.',
                earn: 'Grants access to alternative crypto investment products like Earning, Staking, and Farming.',
                institution: 'Needed if your manager is an institutional client of Single Broker. This right allows the manager to sub-share the trading account within their organization under the permissions given.',
            },
        };
    },
    computed: {
        ...mapGetters({
            isUpdating: 'Accounts/Management/isManagersUpdating',
            activeAccountOutgoingUserManagementRequests: 'Accounts/Management/activeAccountOutgoingUserManagementRequests',
            availablePolicies: 'Accounts/availablePolicies',
        }),
        managers() {
            return this.$store.state.Accounts.Management.accountsManagers[this.$store.getters['Accounts/activeAccountID']];
        },
        hasNoItems() {
            return (this.managersRequests.length === 0 && (this.managers === undefined || this.managers.length === 0));
        },
        outgoingRequests() {
            return this.$store.state.Notifications.outgoingRequests.filter((r) => r.accountId === this.$store.getters['Accounts/activeAccountID']);
        },
        isInstitutionalAllowed() {
            return ![...(this.managers || []), ...(this.managersRequests || [])].some(({ policies }) => policies.institution);
        },
    },
    methods: {
        async updateManagersRequests(requests) {
            if (requests.length === 0) {
                this.managersRequests = [];
                return;
            }

            this.managersRequests = requests.map((r) => {
                return {
                    author: {
                        firstName: r.recipientFirstName,
                        id: r.recipient,
                        kycStatus: 0,
                        lastName: r.recipientLastName,
                    },
                    isRequest: true,
                    policies: {
                        institution: r.policies.includes('institution'),
                        display: r.policies.includes('display'),
                        finance: r.policies.includes('finance'),
                        trading: r.policies.includes('trading'),
                        portfolio: r.policies.includes('portfolio'),
                        allocation: r.policies.includes('allocation'),
                        defi: r.policies.includes('defi'),
                        statements: r.policies.includes('statements'),
                        earn: r.policies.includes('earn'),
                    },
                    requestGuid: r.id,
                };
            });
        },
        openModal(event) {
            this.modalRef = event;
            this.$modal.show('confirm-finance');
        },
        cancel() {
            const component = this.$refs[this.modalRef];
            try {
                if (component.length) {
                    component[0].cancelFinance();
                } else {
                    component.cancelFinance();
                }
            } catch (error) {
                // code crushed because of array length
            }
            this.$modal.hide('confirm-finance');
        },
        confirm() {
            this.$modal.hide('confirm-finance');
        },
    },
    mounted() {
        this.updateManagersRequests(this.outgoingRequests);
    },
    watch: {
        outgoingRequests(requests) {
            this.updateManagersRequests(requests);
            this.$forceUpdate();
        },
        managers(value) {
            this.managersGUIDs = (value === undefined ? [] : value.map((m) => m.author.id));
        },
        managersRequests(value) {
            this.managerRequestsGUIDs = (value === undefined ? [] : value.map((m) => m.author.id));
        },
    },
};
</script>

<style lang="postcss" module="s">
.tableHeader {
    & th {
        width: 75px;
        &:first-child {
            width: 25%;
        }
        &:last-child {
            width: 15%;
        }
    }
}
.pending {
    background: var(--cl-violet-light);
    & .pendingContainer {
        text-align: center;
        & * {
            vertical-align: middle;
            font-weight: var(--fw-bold);
            font-size: var(--fs-m);
            line-height: var(--fs-m);
            color: var(--cl-gray);
            &:first-child {
                margin-right: var(--m-xs);
            }
        }
    }
}
.noData {
    padding: var(--m-m) 0;
}
.row {
    display: flex;
    flex-direction: row;
}
.col {
    display: flex;
    flex-direction: column;
}
.header {
    padding: 8px;
}
.alignCenter {
    align-items: center;
}
.mr8 {
    margin-right: 8px;
}
.headerText {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    text-transform: uppercase;
    color: var(--earn-text);
}
.mainContentText {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: var(--cl-black);
}
.borderBottom {
    border-bottom: 2px solid rgba(146, 143, 168, 0.2);
}
.mainContent {
    padding: 20px 8px;
}
.buttonsBlock {
    padding: 0 8px 8px 8px;
    column-gap: 8px;
}
.w100 {
    width: 100%;
}
.infoRow {
    display: flex;
    align-items: center;
    column-gap: var(--m-xs);
}
</style>

<style>
#confirmModal {
    margin-left: 125px;
}
</style>

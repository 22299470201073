<template>
    <div :class="s.container">
        <div :class="s.module">
            <p @click="goBack" :class="s.goBack">
                ← Go back
            </p>
            <div :class="s.subcontainer">
                <div :class="s.header">
                    <span :class="s.text">
                        Account Access Settings
                    </span>
                    <span :class="s.tag">
                        #{{ $store.getters['Accounts/activeAccountName'] }}
                    </span>
                </div>
                <div>
                    <ManagersTable />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { AccountRoleTypes } from 'Models/accounts';

import ManagersTable from './components/ManagersTable.vue';

export default {
    name: 'Accounts.PermissionsSettings',
    components: {
        ManagersTable,
    },
    data() {
        return {
            changeAccountListenerId: null,
        };
    },
    computed: {
        ...mapGetters({
            activeAccountId: 'Accounts/activeAccountID',
            activeAccountName: 'Accounts/activeAccountName',
            activeAccountRoleType: 'Accounts/activeAccountRoleType',

            hasAvailablePolicies: 'Accounts/hasAvailablePolicies',
        }),
        accountId() {
            return this.$route.params.accountId;
        },
    },
    methods: {
        goBack() {
            if (window.history.length > 2) {
                this.$router.go(-1);
            } else {
                this.$router.push('/terminal');
            }
        },
        save() {
            this.$store.dispatch('Notificator/showSuccessNotification', 'All changes was successfully saved');
            this.goBack();
        },

        updateAccountManagers() {
            if (this.activeAccountRoleType === AccountRoleTypes.OWN) {
                this.$store.dispatch('Accounts/Management/updateAccountManagers', {
                    id: this.activeAccountId,
                });
            } else {
                this.$router.push('/terminal');
            }
        },
    },
    created() {
        if (!this.hasAvailablePolicies) {
            this.$store.dispatch('Accounts/downloadAvailablePolicies');
        }

        if (this.activeAccountId !== null) {
            this.updateAccountManagers();
        }

        this.$store.dispatch('VuexEventListener/addActionListener', {
            type: 'Accounts/setActiveAccount',
            callback: () => {
                this.updateAccountManagers();
            },
        }).then((listenerId) => {
            this.changeAccountListenerId = listenerId;
        });
    },
    destroyed() {
        this.$store.dispatch('VuexEventListener/removeActionListener', {
            type: 'Accounts/setActiveAccount',
            id: this.changeAccountListenerId,
        });
    },
};
</script>

<style lang="postcss" module="s">
.container {
    display: flex;
    width: 100%;
    height: 100%;
    margin-top: calc(2 * var(--m-xxxl));
    justify-content: center;
    & .module {
        margin-bottom: 10%;
    }
    & .subcontainer {
        background: var(--cl-white);
        border-radius: 8px;
        & .header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: var(--m-m);
            border-bottom: 1px solid var(--cl-gray-light);
            & .text {
                font-weight: var(--fw-extrabold);
                font-size: var(--fs-xl);
                line-height: var(--fs-xl);
                color: var(--cl-black);
            }
            & .tag {
                background: var(--cl-violet-light);
                border-radius: 100px;
                padding: var(--m-xs) var(--m-s);
                font-weight: var(--fw-semibold);
                font-size: var(--fs-l);
                line-height: var(--fs-l);
                color: var(--cl-gray);
            }
        }
    }
    & .goBack {
        font-weight: var(--fw-bold);
        font-size: var(--fs-l);
        line-height: var(--fs-l);
        color: var(--cl-violet);
        cursor: pointer;
        margin-bottom: var(--m-s);
    }
}
</style>
